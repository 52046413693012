import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Avatar,
  Button,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DashboardLayout } from "../components/dashboard-layout";
import { ToolBox } from "../components/ToolBox";
import { useEffect, useState } from "react";
import { displayError } from "../utils/misc";
import { useSelector } from "react-redux";
import { usePostsActions } from "../store/server/blog";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const {getPosts, deletePost} = usePostsActions()
  const navigate = useNavigate()
  const {isPending} = useSelector(state => state.posts)
  const [data, setData] = useState();

  const fetchPosts = async (page = 1) => {
    const {payload, error} = await getPosts({page})
    if(error){
      return displayError(error.message)
    }
    setData(payload)
  }

  useEffect(() => {
    fetchPosts(1);
    // eslint-disable-next-line
  }, []);

  const onDelete = async (item) => {
    // show an alert and on confirm delete the item
    // eslint-disable-next-line
    prompt = window.confirm("Are you sure you want to delete this item?");

    if (prompt) {
      await deletePost(item.id);
      fetchPosts(1);
    } else {
      // do nothing
    }
  };

  return (
    <DashboardLayout>
      {data && <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <ToolBox
            title="Blog"
            loading={isPending}
            onClick={() => {
              navigate('/post')
            }}
            addTitle="Add Post"
          />

          <Box sx={{ mt: 3 }}>
            <Card>
              <PerfectScrollbar >
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.results &&
                        data.results.map((post) => (
                          <TableRow hover key={post.id}>
                            <TableCell>
                              <Avatar
                                alt={post.title}
                                src={post?.image}
                                sx={{ width: 56, height: 56 }}
                              />
                            </TableCell>
                            <TableCell>{post.title}</TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                variant="contained"
                                sx={{
                                  width: '150px'
                                }}
                                onClick={() => {
                                  navigate(`/post/${post.id}`)
                                }}
                              >
                                View
                              </Button>
                              <div style={{ width: 10, height: 10 }} />
                              <Button
                                color="warning"
                                variant="contained"
                                sx={{
                                  width: '150px'
                                }}
                                onClick={() => {
                                  onDelete(post);
                                }}
                                disabled={isPending}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={data.totalResults? data.totalResults : 1}
                onPageChange={(e, page) => {
                  fetchPosts(page + 1);
                }}
                page={(data?.page? data?.page: 1) - 1}
                rowsPerPage={data?.limit?data?.limit: 1}
                rowsPerPageOptions={[data?.limit?data?.limit: 1]}
              />
            </Card>
          </Box>
        </Container>
      </Box>}
    </DashboardLayout>
  );
};

export default Page;
