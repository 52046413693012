import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DashboardLayout } from "../components/dashboard-layout";
import { ToolBox } from "../components/ToolBox";
import { useEffect, useState } from "react";
import { displayError } from "../utils/misc";
import { useCommonActions, useServicesActions } from "../store";
import { useSelector } from "react-redux";

const Page = () => {
  const { getServiceNames, createServiceName, updateServiceName } =
    useServicesActions();
  const { getCategories } = useCommonActions();

  const { categories } = useSelector((state) => state.common);
  const { isPending } = useSelector((state) => state.services);

  const [data, setData] = useState();

  const getData = async ({ page = 1 }) => {
    const { payload, error } = await getServiceNames({ page });
    if (error) {
      displayError(error.message);
    }
    setData(payload);
  };

  const [showDialog, setShowDialog] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    getData({ page: 1 });
    getCategories();
    // eslint-disable-next-line
  }, []);

  const handleDialog = (info, state = true) => {
    setSelectedData(info);
    setShowDialog(state);
  };

  const onSubmit = async () => {
    if (selectedData.id) {
      await updateServiceName({
        id: selectedData.id,
        body: {
          value: selectedData.value,
          category: selectedData.category,
        },
      });
    } else {
      await createServiceName(selectedData);
    }

    setShowDialog(false);
    getData({ page: 1 });
  };

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <ToolBox
            title="Service Names"
            loading={isPending}
            onClick={() => {
              handleDialog({}); // open dialog
            }}
            addTitle="Add Service Name"
          />

          <Box sx={{ mt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!data?.results ?
                        data.results.map((item) => {
                          const cat =
                            categories.results?.find(
                              (c) => String(c.id) === String(item.category)
                            ) || {};
                          return (
                            <TableRow hover key={item.id}>
                              <TableCell>
                                <TableCell>{item.value}</TableCell>
                              </TableCell>
                              <TableCell>{cat.name}</TableCell>
                              <TableCell>
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => {
                                    handleDialog(item);
                                  }}
                                  disabled={isPending}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        }): null}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={data?.totalResults ? data.totalResults : 1}
                onPageChange={(e, page) => {
                  getData(page + 1);
                }}
                page={(data?.page ? data?.page : 1) - 1}
                rowsPerPage={data?.limit ? data?.limit : 1}
                rowsPerPageOptions={[data?.limit ? data?.limit : 1]}
              />
            </Card>
          </Box>
        </Container>
      </Box>

      <Dialog
        open={showDialog}
        onClose={() => {
          handleDialog({}, false);
        }}
        sx={{ minWidth: 500 }}
      >
        <DialogTitle> Service Name Details </DialogTitle>

        <DialogContent sx={{ minWidth: 500 }}>
          <DialogContentText>Create or update a Service Name</DialogContentText>
          <FormControl fullWidth>
            <TextField
              fullWidth
              margin="dense"
              variant="standard"
              label="Service Name"
              value={selectedData.value || ""}
              onChange={(e) => {
                setSelectedData({
                  ...selectedData,
                  value: e.target.value,
                });
              }}
            />
          </FormControl>
          <div
            style={{
              height: 30,
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedData.category}
              label="Age"
              onChange={(e) => {
                setSelectedData({
                  ...selectedData,
                  category: e.target.value,
                });
              }}
            >
              {!!categories?.results?
                categories.results.map((e) => {
                  return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>;
                }): null}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDialog({}, false);
            }}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            disabled={isPending}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Page;
