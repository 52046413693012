import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "@mui/material";
import "./styles.css";
import { toolbarOption } from "./toolbarOption";

const RichText = ({ value = "", setValue = () => {} }) => {
  const [state, setState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setState(editorState);
    }
  }, [value]);
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Editor
        editorState={state}
        placeholder="Post text"
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={setState}
        toolbar={toolbarOption}
        handlePastedText={text => {
          const contentState = Modifier.replaceText(
            state.getCurrentContent(),
            state.getSelection(),
            text,
            state.getCurrentInlineStyle(),
          );
          setState(EditorState.push(state, contentState, 'insert-characters'));
        }}
      />
      <Button
        variant="contained"
        sx={{
          maxWidth: '200px',
          marginBottom: '20px'
        }}
        onClick={() =>
          setValue(draftToHtml(convertToRaw(state.getCurrentContent())))
        }
      >
        Change
      </Button>
    </div>
  );
};

export default RichText;
