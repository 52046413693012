import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DashboardLayout } from "../components/dashboard-layout";
import { ToolBox } from "../components/ToolBox";
import { useEffect, useState } from "react";
import { displayError } from "../utils/misc";
import { useCommonActions } from "../store";
import { useSelector } from "react-redux";

const Page = () => {
  const { getCities, updateCity, deleteCity, createCity } = useCommonActions();
  const { cities: data, isPending } = useSelector((state) => state.common);
  const [showDialog, setShowDialog] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  const getData = async (page = 1) => {
    const { error } = await getCities({ page });
    if (error) {
      return displayError(error.message);
    }
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line
  }, []);

  const handleDialog = (info, state = true) => {
    setSelectedData(info);
    setShowDialog(state);
  };

  const onSubmit = async () => {
    if (selectedData.id) {
      await updateCity({
        id: selectedData.id,
        data: {
          name: selectedData.name,
          region: selectedData.region,
        },
      });
    } else {
      await createCity(selectedData);
    }

    setShowDialog(false);
    getData({ page: 1 });
  };

  const onDelete = async (item) => {
    // show an alert and on confirm delete the item
    // eslint-disable-next-line
    prompt = window.confirm("Are you sure you want to delete this item?");

    if (prompt) {
      await deleteCity(item.id);
      getData(1);
    } else {
      // do nothing
    }
  };

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <ToolBox
            title="Cities"
            loading={isPending}
            onClick={() => {
              handleDialog({}); // open dialog
            }}
            addTitle="Add City"
          />

          {data && <Box sx={{ mt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Region</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.results &&
                        data.results.map((item) => (
                          <TableRow hover key={item.id}>
                            <TableCell>
                              <TableCell>{item.name}</TableCell>
                            </TableCell>
                            <TableCell>{item.region}</TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handleDialog(item);
                                }}
                                disabled={isPending}
                              >
                                View
                              </Button>
                              <div style={{ width: 10, height: 10 }} />
                              <Button
                                color="warning"
                                variant="contained"
                                onClick={() => {
                                  onDelete(item);
                                }}
                                disabled={isPending}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={data?.totalResults ? data.totalResults : 1}
                onPageChange={(e, page) => {
                  getData(page + 1);
                }}
                page={(data?.page ? data?.page : 1) - 1}
                rowsPerPage={data?.limit ? data?.limit : 1}
                rowsPerPageOptions={[data?.limit ? data?.limit : 1]}
              />
            </Card>
          </Box>}
        </Container>
      </Box>

      <Dialog
        open={showDialog}
        onClose={() => {
          handleDialog({}, false);
        }}
        sx={{ minWidth: 500 }}
      >
        <DialogTitle> City Details </DialogTitle>

        <DialogContent sx={{ minWidth: 500 }}>
          <DialogContentText>Create or update a city</DialogContentText>

          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            label="City Name"
            value={selectedData.name || ""}
            onChange={(e) => {
              setSelectedData({
                ...selectedData,
                name: e.target.value,
              });
            }}
          />

          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            label="Region Name"
            value={selectedData.region || ""}
            onChange={(e) => {
              setSelectedData({
                ...selectedData,
                region: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDialog({}, false);
            }}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            disabled={isPending}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Page;
