import { Box, Button, Container, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePostsActions } from "../store/server/blog";
import { displayError } from "../utils/misc";
import { useSelector } from "react-redux";
import RichText from "../components/RichText/component";
import './index.css'

const Page = () => {
  const { getOnePost, updatePost, createPost } = usePostsActions();
  const { isPending } = useSelector((state) => state.posts);
  const params = useParams();
  const navigate = useNavigate();
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchPost = async () => {
    const { payload, error } = await getOnePost(params.id);
    if (error) {
      return displayError(error.message);
    }
    setSelectedPost(payload);
  };

  useEffect(() => {
    if (!!params?.id) {
      fetchPost();
    }
    // eslint-disable-next-line
  }, [params?.id]);

  const onSubmit = async () => {
    if (!selectedPost.title) return displayError("Post title is required");

    if (!selectedPost.image && !selectedImage)
      return displayError("Post image is required");

    if (selectedPost.id) {
      const formData = new FormData();
      formData.append("title", selectedPost.title);
      formData.append("body", selectedPost.body);
      if (selectedImage) formData.append("image", selectedImage);
      const { error } = await updatePost({
        id: selectedPost.id,
        body: formData,
      });
      if (error) {
        return displayError(error.message);
      }
      navigate("/");
      return;
    }

    const formData = new FormData();
    formData.append("title", selectedPost.title);
    formData.append("body", selectedPost.body);
    formData.append("image", selectedImage);
    const { error } = await createPost(formData);
    if (error) {
      return displayError(error.message);
    }
    navigate("/");
  };

  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100vh",
        backgroundColor: "#e0f4ff",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: "20px",
          }}
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() => {
              navigate(`/`);
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
          <div style={{ width: 10, height: 10 }} />
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
            disabled={isPending}
          >
            Publish
          </Button>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button variant="contained" component="label" sx={{
            maxWidth: '200px',
            alignSelf: 'center'
          }}>
            Upload Image
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                setSelectedImage(e.target.files[0]);
              }}
            />
          </Button>
          {(selectedPost && selectedPost?.image) || selectedImage ? (
            <img
              name="image"
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : selectedPost?.image
              }
              alt="category"
              style={{
                width: '90%',
                maxWidth: '800px',
                borderRadius: 10,
                alignSelf: 'center',
                maxHeight: "450px",
                objectFit: "cover",
              }}
            />
          ) : null}
          <h2
            style={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {selectedPost.title}
          </h2>
          <div
            className="post-wrapper"
            dangerouslySetInnerHTML={{
              __html: selectedPost.body,
            }}
          />
        </div>
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          label="Post title"
          sx={{
            backgroundColor: '#FFF',
            borderRadius: '5px',
          }}
          value={selectedPost.title || ""}
          onChange={(e) => {
            setSelectedPost({
              ...selectedPost,
              title: e.target.value,
            });
          }}
        />
        <Box sx={{ mt: "10px" }}>
          <RichText
            value={selectedPost.body}
            setValue={(item) =>
              setSelectedPost({ ...selectedPost, body: item })
            }
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
