export const toolbarOption = {
  options: [
    "inline",
    // "blockType",
    "fontSize",
    // "fontFamily",
    "list",
    "textAlign",
    // "colorPicker",
    // "link",
    // "embedded",
    // "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline"],
  },
  fontSize: {
    inDropdown: true,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    // className: undefined,
    // component: undefined,
    // dropdownClassName: 'fontSizeDropdown',
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered"],
  },
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: false,
    uploadEnabled: true,
    alignmentEnabled: false,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
};
