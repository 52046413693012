import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DashboardLayout } from "../components/dashboard-layout";
import { ToolBox } from "../components/ToolBox";
import { useEffect, useState } from "react";
import { displayError } from "../utils/misc";
import { useCommonActions } from "../store";
import { useSelector } from "react-redux";

const Page = () => {
  const {getCategories, updateCategory, createCategory} = useCommonActions()
  const {isPending, categories: data} = useSelector(state => state.common)
  const [showDialog, setShowDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchCategories = async (page = 1) => {
    const {error} = await getCategories({page})
    if(error){
      return displayError(error.message)
    }
  }

  useEffect(() => {
    fetchCategories(1);
    // eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    if (!selectedCategory.name)
      return displayError("Category name is required");

    if (!selectedCategory.image && !selectedImage)
      return displayError("Category image is required");

    if (selectedCategory.id) {
      const formData = new FormData();
      formData.append("name", selectedCategory.name);
      if (selectedImage) formData.append("image", selectedImage);
      const {error} = await updateCategory({ id: selectedCategory.id, body: formData });
      if(error){
        return displayError(error.message)
      }
      handleDialog({}, false);
      fetchCategories(1); // refresh
      return;
    }

    const formData = new FormData();
    formData.append("name", selectedCategory.name);
    formData.append("image", selectedImage);
    const {error} = await createCategory(formData);
    if(error) {
      return displayError(error.message)
    }
    handleDialog({}, false);
    fetchCategories(1); // refresh
  };

  const handleDialog = (category, state = true) => {
    setSelectedCategory(category);
    setSelectedImage(null);
    setShowDialog(state);
  };

  return (
    <DashboardLayout>
      <Dialog
        open={showDialog}
        onClose={() => {
          handleDialog({}, false);
        }}
        sx={{ minWidth: 500 }}
      >
        <DialogTitle> Category Details </DialogTitle>

        <DialogContent sx={{ minWidth: 500 }}>
          <DialogContentText>Create or update a category</DialogContentText>

          {(selectedCategory && selectedCategory?._image?.min) || selectedImage ? (
            <img
              name="image"
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : selectedCategory?._image?.min
              }
              alt="category"
              style={{ width: "100%", height: "auto" }}
            />
          ) : null}

          <Button variant="contained" component="label">
            Upload Image
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                setSelectedImage(e.target.files[0]);
              }}
            />
          </Button>

          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            label="Category Name"
            value={selectedCategory.name || ""}
            onChange={(e) => {
              setSelectedCategory({
                ...selectedCategory,
                name: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDialog({}, false);
            }}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            disabled={isPending}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

      {data && <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <ToolBox
            title="Categories"
            loading={isPending}
            onClick={() => {
              handleDialog({}); // open dialog
            }}
            addTitle="Add Category"
          />

          <Box sx={{ mt: 3 }}>
            <Card>
              <PerfectScrollbar >
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.results &&
                        data.results.map((category) => (
                          <TableRow hover key={category.id}>
                            <TableCell>
                              <Avatar
                                alt={category.name}
                                src={category?._image?.min}
                                sx={{ width: 56, height: 56 }}
                              />
                            </TableCell>
                            <TableCell>{category.name}</TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  setSelectedCategory(category);
                                  setShowDialog(true);
                                }}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={data.totalResults? data.totalResults : 1}
                onPageChange={(e, page) => {
                  fetchCategories(page + 1);
                }}
                page={(data?.page? data?.page: 1) - 1}
                rowsPerPage={data?.limit?data?.limit: 1}
                rowsPerPageOptions={[data?.limit?data?.limit: 1]}
              />
            </Card>
          </Box>
        </Container>
      </Box>}
    </DashboardLayout>
  );
};

export default Page;
